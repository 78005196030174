// src/pages/MediaProduction.js
import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Modal, Image } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import media1 from "../assets/media/media1.png";
import media2 from "../assets/media/media2.png";
import media3 from "../assets/media/media3.png";
import media4 from "../assets/media/media4.jpg";
import media7 from "../assets/media/media7.jpg";
import media10 from "../assets/media/media10.jpg";
import media15 from "../assets/media/media15.jpg";
import media16 from "../assets/media/media16.jpg";
import media18 from "../assets/media/media18.jpg";

const { Content } = Layout;

const MediaProduction = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videoId, setVideoId] = useState();
  const showModal = (id) => {
    setVideoId(id);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const listVideoId = [
    {
      title: "OriginalBurger - GoodTime Burger Commercial",
      value: ["FwbkAIns5QQ", "Nv_BDriTd1I"],
      img: media2,
    },
    {
      title: "Chess club promotion",
      value: ["yjJUSpw9afU"],
      img: media18,
    },
    {
      title: "Banh Trung Thu - Cooking Portfolio",
      value: ["4ppx34ojCsE"],
      img: media3,
    },

    {
      title: "CHƯƠNG TRÌNH ART & DESIGN TẠI VINSCHOOL THE HARMONY",
      value: ["gd0irqK600U"],
      img: media4,
    },
    {
      title: "Pathways educational reels - GUESS THE TOP 5 HAPPIEST MAJORS",
      value: ["Usz9JopyuIk"],
      img: media15,
    },
    {
      title: "INTERVIEW 2 - Các bạn có tò mò ....",
      value: ["VJB2WiyTVyg"],
      img: media10,
    },
    {
      title: "MONG MUỐN TỪ CÁC VINSER LÀ GÌ",
      value: ["em3X3ZhQm9o"],
      img: media7,
    },
    {
      title: "Podcast: “Chủ động” trong một mối quan hệ là như thế nào? - THR",
      img: media16,
      value: ["rtwBxDEufLw"],
    },
  ];

  const VideoModal = () => {
    return (
      <Modal
        title="Watch Video"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="100%"
        styles={{ padding: 0 }}
        centered
        closeIcon={<span style={{ color: "white" }}>✖</span>}
        style={{ padding: 0, maxWidth: "1200px" }}
      >
        <div>
          {videoId &&
            videoId.map((vid, index) => (
              <iframe
                key={index}
                style={{ width: "100%", height: "60vh", marginBottom: "40px" }}
                src={`https://www.youtube.com/embed/${vid}?autoplay=1`}
                allowFullScreen
                title={`Video ${index + 1}`}
              />
            ))}
        </div>
      </Modal>
    );
  };

  return (
    <Layout style={{ height: "100%", background: "none" }}>
      <Content
        style={{ padding: "40px 20px", maxWidth: "1200px", margin: "0 auto" }}
      >
        <Row gutter={[30, 30]}>
          {listVideoId.map((video, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              onClick={() => showModal(video.value)}
            >
              <div style={styles.filmCard}>
                <img
                  src={video.img}
                  alt={`Image for ${video.img}`}
                  style={{
                    width: "100%",
                    height: "400px",
                    objectFit: "cover",
                  }}
                />
                <PlayCircleOutlined style={styles.icon} />
              </div>
            </Col>
          ))}

          <Col span={24} onClick={() => showModal(["XbOYtGBm91s"])}>
            <div style={styles.filmCard}>
              <img
                src={media1}
                alt={`Image for vinhome`}
                style={{
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                }}
              />
              <PlayCircleOutlined style={styles.icon} />
            </div>
          </Col>
        </Row>
        <VideoModal
          isVisible={isModalVisible}
          onClose={handleCancel}
          videoId={videoId}
        />
      </Content>
    </Layout>
  );
};

const styles = {
  filmCard: {
    height: "400px",
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    fontSize: "40px",
    transform: "translate(-50%, -50%)",
  },
};

export default MediaProduction;
