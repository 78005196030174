// src/components/Footer.tsx
import React from "react";
import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.socialIcons}>
        <a
          href="https://www.facebook.com/profile.php?id=100010363509445&mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.icon}
        >
          <FacebookOutlined style={{ fontSize: "24px", color: "#a4864f" }} />
        </a>
        <a
          href="https://www.instagram.com/bent.___/profilecard/?igsh=bWRkNDRsbzUzMG4x"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.icon}
        >
          <InstagramOutlined style={{ fontSize: "24px", color: "#a4864f" }} />
        </a>
        <a
          href="https://youtube.com/@icestudio7637?si=vnxxcdPjn6DF9PUu"
          target="_blank"
          rel="noopener noreferrer"
          style={styles.icon}
        >
          <YoutubeOutlined style={{ fontSize: "24px", color: "#a4864f" }} />
        </a>
      </div>
      <p style={styles.text}>© Bentley Nguyen.</p>
    </footer>
  );
};

const styles = {
  footer: {
    padding: "32px 16px",
    textAlign: "center",
    height: "50px",
    margin: "30px 0px 0px 0px",
  },
  socialIcons: {
    marginBottom: "10px",
  },
  icon: {
    margin: "0 10px",
    color: "#a4864f",
  },
  text: {
    margin: "0",
    color: "#fff",
    fontWeight: 500,
  },
};

export default Footer;
