import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import "./Header.css";

const items = [
  {
    key: "1",
    label: (
      <NavLink to="/" className="nav__link" activeClassName="active">
        Films
      </NavLink>
    ),
  },
  {
    key: "2",
    label: (
      <NavLink
        to="/media-production"
        className="nav__link"
        activeClassName="active"
      >
        Media Production
      </NavLink>
    ),
  },
  {
    key: "3",
    label: (
      <NavLink to="/about" className="nav__link" activeClassName="active">
        About Me
      </NavLink>
    ),
  },

  {
    key: "4",
    label: (
      <NavLink to="/contact" className="nav__link" activeClassName="active">
        Contact
      </NavLink>
    ),
  },
];
const Header = () => {
  const [background, setBackground] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 50) {
        setBackground("rgba(0, 0, 0)");
      } else {
        setBackground("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header" style={{ backgroundColor: background }}>
      <nav className="nav container">
        <a href="/" className="nav__logo">
          <b>Bentley Nguyen.</b>
        </a>

        <div className={`nav__menu`} id="nav-menu">
          {/* desktop */}
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink to="/" className="nav__link">
                Films
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/media-production" className="nav__link">
                Media Production
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/about" className="nav__link">
                About Me{" "}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/contact" className="nav__link">
                Contact
              </NavLink>
            </li>
          </ul>

          {/* mobile */}
          <div className="nav__list_mobile">
            <Dropdown menu={{ items }} placement="bottomRight">
              <MenuOutlined style={{ fontSize: "24px", color: "#fff" }} />
            </Dropdown>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
