// src/routes/index.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Films from "../pages/Films";
import MediaProduction from "../pages/MediaProduction";
import Contact from "../pages/Contact";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Films />} />
      <Route path="/media-production" element={<MediaProduction />} />
      <Route path="/about" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};

export default AppRoutes;
