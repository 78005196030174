import React from "react";
import { Layout, Row, Col, Divider, Image, Button } from "antd";
import avtImage from "../assets/Ava.jpg";
import homeGrand1 from "../assets/home/1.jpg";
import homeGrand2 from "../assets/home/2.jpg";
import homeGrand3 from "../assets/home/3.jpg";
import homeGrand4 from "../assets/home/4.jpg";
import homeGrand5 from "../assets/home/5.png";
import homeGrand6 from "../assets/home/6.jpg";
import homeGrand7 from "../assets/home/7.png";
import homeGrand8 from "../assets/home/8.jpg";

const { Content } = Layout;

const Home = () => {
  const listGrand = [
    {
      key: 2,
      value: homeGrand1,
    },
    {
      key: 3,
      value: homeGrand2,
    },
    {
      key: 9,
      value: homeGrand3,
    },
    {
      key: 4,
      value: homeGrand4,
    },
    {
      key: 5,
      value: homeGrand5,
    },
    {
      key: 6,
      value: homeGrand6,
    },
    {
      key: 7,
      value: homeGrand7,
    },
    {
      key: 8,
      value: homeGrand8,
    },
  ];
  return (
    <Layout style={{ height: "100%", background: "none" }}>
      <Content
        style={{ padding: "40px 20px", maxWidth: "1200px", margin: "0 auto" }}
      >
        <Row gutter={[30, 30]}>
          <Col xs={24} sm={24} md={8}>
            <div>
              <Image width="100%" src={avtImage} />
            </div>
          </Col>

          <Col xs={24} sm={24} md={16}>
            <div>
              <h2>
                <br />
              </h2>
              <h2 style={{ textAlign: "center" }}>
                <b>ABOUT</b>
              </h2>
              <p style={{ textAlign: "justify" }}>
                Bentley Nguyen is just a guy from Hanoi, Vietnam who has loved
                making films ever since he was 10. He's currently a student but
                doesn't allow his young age to prevent him from making films
                that are simply fun entertainment or telling powerful stories
                that challenge societal issues. Bentley aims to take untold
                Vietnamese stories to international cinema, and hopefully in the
                process, achieve his dream of making Vietnamese cinema greater.
              </p>
              <p style={{ textAlign: "justify" }}>
                Bentley was born in Vancouver, Canada by two hardworking
                Vietnamese immigrants. When he was 3, he and his family went to
                Vietnam to live. This time was when he developed his love for
                Vietnamese culture and fell in love with Vietnam's stories and
                legendary folklores.
              </p>

              <p style={{ textAlign: "justify" }}>
                5 years later, Bentley moved back to Canada again. During this
                time he began watching movies, a lot of it. It started with
                Raimi's Spider-Man trilogy, then slowly moved to more mature
                films such as Gladiator, Goodfellas, Silence of the Lambs, and
                (his favourite) Inception.
              </p>
              <p style={{ textAlign: "justify" }}>
                When Bentley turned 10, he returned to live in Vietnam again.
                And this was when he began making movies. It didn't start off
                with Sony cameras, Boom mics, or a professional film crew.
                Instead, it was just Bentley filming his friends with his iPad
                and editing it on iMovie. Nonetheless, Bentley never stopped
                making films. As time passed, he continued to hone his skills.
                The iPad upgraded to a proper camera. iMovie upgraded to DaVinci
                Resolve. Stories about sandwiches evolved into stories about
                social issues. Eventually, Bentley started making award-winning
                films, not only in Vietnam but around the world too.
              </p>
            </div>
            <div
              style={{
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <a
                href="https://drive.google.com/file/d/1jZzd0BN5RadSwCz5WaECFyVwhXp4kwHt/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="primary">RESUME</Button>
              </a>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <p>
              <b>AWARDS &amp; SELECTIONS</b>
            </p>
            <Divider style={{ borderColor: "#a4864f", margin: "10px 0px" }} />
          </Col>
          <Col span={24}></Col>
          <Col span={24}>
            <ul
              style={{
                fontWeight: "600",
                lineHeight: "180%",
              }}
            >
              <li>
                2024 - Golden Lion International Film Festival (GLIFF) [IMDB
                Qualifying]
              </li>
              <li>2024 - Best Student Film, Bangkok Movie Awards</li>
              <li>
                2024 - Finalist for Best Documentary Short, New York
                International Films Infest Festival (NYCIFIF) [IMDB Qualifying]
              </li>
              <li>
                2024 - Official Selection for Documentary Short, Barcelona Indie
                Filmmakers Festival (BARCIFF) [IMDB Qualifying]
              </li>
              <li>
                2024 - Official Selection for Documentary Short, Montpellier
                Indie Film Festival (MONTIFF) [IMDB Qualifying]
              </li>
              <li>
                2024 - Official Selection for Documentary Short, Alibag Short
                Film Festival
              </li>
              <li>
                2024 - Best Cinematography - Best Sound - Best Editing, Olympia
                Film Festival
              </li>
              <li>
                2023 - First Runner Up of Best Film - Best Screenplay - Best
                Cinematography - Best Sound - Best Editing, Olympia Film
                Festival
              </li>
              <li>
                2023 - First Place, Samsung’s Solve for Tomorrow 2023 (Directed
                and produced winner’s video)
              </li>
              <li>
                2022 - Best Film - Best Cinematography - Best Sound, Olympia
                Film Festival
              </li>
            </ul>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Row gutter={[16, 16]}>
          <Col span={8}></Col>
          <Col span={8}>
            <Divider
              style={{ borderColor: "#a4864f", width: "50%", margin: "0 auto" }}
            />
          </Col>
          <Col span={8}></Col>
        </Row>
        <br />
        <br />
        <br />

        <Row gutter={[30, 30]}>
          {listGrand.map((item) => (
            <Col key={item.key} xs={24} sm={12} md={6}>
              <div>
                <img
                  src={item.value}
                  alt={`Image for ${item.value}`}
                  style={{
                    width: "100%",
                    height: "250px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
        <br />
        <Row>
          <Col>
            <div class="format-instagram-attribution">
              Follow
              <a
                href="https://www.instagram.com/bent.___/profilecard/?igsh=bWRkNDRsbzUzMG4x"
                style={{ color: "#a4864f" }}
                target="_blank"
                rel="noopener"
              >
                <b> @bent.___ </b>
              </a>
              on Instagram
            </div>
          </Col>
          <br />
        </Row>
      </Content>
    </Layout>
  );
};

export default Home;
