import React from "react";
import { Button, Layout } from "antd";
import videoBG from "../assets/contact/contact.mp4";
import "./Contact.css";
const { Content } = Layout;

const Contact = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:bentocanada@gmail.com";
  };

  return (
    <Layout style={{ height: "100vh", position: "relative" }}>
      <video autoPlay muted loop id="myVideo" className="background-video">
        <source src={videoBG} type="video/mp4" />
      </video>

      <Content
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          zIndex: 1,
          color: "#fff",
        }}
      >
        <p style={{ fontSize: "48px" }}>
          <b>CONTACT ME</b>
        </p>
        <p className="large-text">bentocanada@gmail.com</p>
        <p className="large-text">icestudiofilms2020@gmail.com</p>
        <div
          style={{
            color: "#fff",
            border: "2px solid #fff",
            background: "transparent",
            margin: "26px 0px",
            fontSize: "20px",
            padding: "16px 32px",
          }}
          size="large"
          onClick={handleEmailClick}
        >
          EMAIL
        </div>
      </Content>
    </Layout>
  );
};
export default Contact;
