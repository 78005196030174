import React, { useState } from "react";
import { Layout, Row, Col, Modal } from "antd";
import XuanSunset from "../assets/films/XuanSunset.png";
import BaconLettuceTomato from "../assets/films/BaconLettuceTomato.png";
import RunWiththeWind from "../assets/films/RunWiththeWind.png";
import thebox from "../assets/films/thebox.png";
import TheSteepPath from "../assets/films/TheSteepPath.png";
import IntheMidstofInvisibleDreams from "../assets/films/IntheMidstofInvisibleDreams.png";
import ThoseEyes from "../assets/films/ThoseEyes.png";

const { Content } = Layout;

const Films = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [videoId, setVideoId] = useState(); // default

  const showModal = (id) => {
    setVideoId(id);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const listVideoIDOLD = {
    title: "Xuan | Short Film",
    value: "9TxvdNEvEPM",
    img: XuanSunset,
    key: 1,
    content: `
      <p>Xuan, a Vietnamese boy, returns to Vietnam after 10 years of studying abroad. He then has to face his traumatic past with his brother and rekindle his relationship with an old friend.</p>
      <p>This film explores toxic masculinity and homophobia in Vietnamese culture, revealing how cultural norms shape attitudes and behaviors. It aims to spark conversations and encourage a more inclusive and compassionate society.</p>
      <p><span class="thin-text">Best International Short Film - Golden Lion International Film Festival (GLIFF) 2024</span></p>
      <p><span class="thin-text">Best Student Film - Bangkok Movie Awards 2024</span></p>
    `,
  };

  const listVideoId = [
    {
      title: "The Steep Path | Short Documentary",
      value: "BwmDBx2_xN0",
      img: TheSteepPath,
      key: 2,
      content: `
      <p>Settled in the Northern Midlands and Mountains of Vietnam, the H'mong people endure countless struggles with resilience and solidarity. Yet, beneath it all, their dreams remain unbroken.</p>
      <p>The H'mong, one of Vietnam's 54 ethnic groups, often have their stories of struggle and resilience overlooked. While some H'mong villages are tourist spots, most remain isolated in the mountains. Despite challenges, I directed "The Steep Path" to authentically portray their lives. After securing permits and trekking to remote areas, we captured the resilience and dreams of H'mong villagers.</p>
      <p><span class="thin-text">Finalist - New York International Films Infest Festival (NYCIFIF) 2024</span></p>
      <p><span class="thin-text">Official Selection - Barcelona Indie Filmmakers Festival (BARCIFF) 2024</span></p>
      <p><span class="thin-text">Official Selection - Montpellier Indie Film Festival (MONTIFF) 2024</span></p>
      <p><span class="thin-text">Official Selection - Alibag Short Film Festival 2024</span></p>
      <p><span class="thin-text">Official Selection - Napier Student Film Festival 2024</span></p>

    `,
    },
    {
      title: "Those Eyes | Short Film",
      value: "Ns7eLzuvWoE",
      img: ThoseEyes,
      key: 3,
      content: `
      <p>A mundane guy goes through his day until he meets a cute waitress.
</p>
      <p>A romantic story about the contrast between our imaginations and reality</p>
      <p><span class="thin-text"></span></p>
    `,
    },
    {
      title: "The Box | Short Film",
      value: "FlkPVraTs9Q",
      img: thebox,
      key: 5,
      content: `
      <p>3 spies decide whether to open a box or not.
</p>
      <p>A story about how we deceive ourselves and let our ego shape our decisions, revealing moments when one’s intuition overpowers logic–leading to dire consequences.
</p>
      <p><span class="thin-text">Won 3 awards Olympia Festival 2024.</span></p>
    `,
    },
    {
      title: "Run with the Wind | Short Film",
      value: "u_9Y1kfjAjY",
      img: RunWiththeWind,
      key: 4,
      content: `
      <p>A crippled student tries to balance academics with his life goal of running.
</p>
      <p>A tragic story warning viewers the dangers of being trapped in balancing passion and duty
</p>
      <p><span class="thin-text">Won 4 awards Olympia Festival 2023</span></p>
    `,
    },
    {
      title: "Bacon Lettuce Tomato | Short Film",
      value: "HUYzz3tdCTo",
      img: BaconLettuceTomato,
      key: 6,
      content: `
      <p>Tom eats Josh’s BLT sandwich. He then pays a hefty price for it.
</p>
      <p>The story delves into the psychological conflict between personal desires and family bonds, highlighting the challenge of preserving relationships amid moral dilemmas.
</p>
      <p><span class="thin-text">Won 3 awards at Olympia Festival 2022, including Best Film</span></p>
    `,
    },
    {
      title: "Within the invisible dream | Short Film",
      value: "Ve11yO11_v8",
      img: IntheMidstofInvisibleDreams,
      key: 7,
      content: `
      <p>A young Vietnamese boy struggles to cope with his father's loss in the war.</p>
      <p></p>
      <p><span class="thin-text"></span></p>
    `,
    },
  ];

  const VideoModal = () => {
    return (
      <Modal
        title="Watch Video"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="100%"
        styles={{ padding: 0 }}
        centered
        closeIcon={<span style={{ color: "white" }}>✖</span>}
        style={{
          padding: 0,
          margin: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "#000",
        }}
      >
        {videoId && (
          <div style={styles.containerFilms}>
            <iframe
              style={styles.iframeFilms}
              src={`https://www.youtube.com/embed/${videoId.value}?autoplay=1`}
              allowFullScreen
              title="Video"
            />
            <div dangerouslySetInnerHTML={{ __html: videoId.content }} />{" "}
          </div>
        )}
      </Modal>
    );
  };

  return (
    <Layout style={{ height: "100%", background: "none" }}>
      <Content
        style={{ padding: "40px 20px", maxWidth: "1200px", margin: "0 auto" }}
      >
        <Row gutter={[30, 30]}>
          <Col
            span={24}
            onClick={() => showModal(listVideoIDOLD)}
            style={styles.filmCard}
          >
            <img
              src={listVideoIDOLD.img}
              alt={`Image for ${listVideoIDOLD.img}`}
              style={styles.img}
            />
          </Col>

          {listVideoId.map((video, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              onClick={() => showModal(video)}
              style={styles.filmCard}
            >
              <img
                src={video.img}
                alt={`Image for ${video.img}`}
                style={styles.img}
              />
            </Col>
          ))}
        </Row>
        <VideoModal
          isVisible={isModalVisible}
          onClose={handleCancel}
          videoId={videoId}
        />
      </Content>
    </Layout>
  );
};

const styles = {
  filmCard: {
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: "10px",
  },
  img: {
    width: "100%",
    height: "400px",
    objectFit: "cover",
    transition: "0.3s ease-in-out",
  },
  containerFilms: {
    width: "100%",
    aspectRatio: "16 / 9",
  },
  iframeFilms: {
    width: "100%",
    height: "100%",
    border: "none",
  },
};

export default Films;
