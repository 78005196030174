import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRoutes from "./routes";
import background from "./assets/background.jpg";

function App() {
  return (
    <Router>
      <AppContainer />
    </Router>
  );
}

function AppContainer() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      backgroundImage:
        location.pathname === "/contact" ? "none" : `url(${background})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundAttachment: "fixed",
    },
    body: {
      marginTop: location.pathname === "/contact" ? "" : "80px",
    },
  };

  return (
    <div style={styles.container}>
      <Header />
      <div style={styles.body}>
        <AppRoutes />
      </div>
      <Footer />
    </div>
  );
}

export default App;
